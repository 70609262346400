/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  var Fn = {
    goTo : function(target, speed, adapt){
      // Scroll animation
      var toTop = (typeof adapt !== 'undefined') ? $(target).offset().top - $('.header').height(): $(target).offset().top;
      $('html, body').animate({scrollTop: toTop}, speed);
    },
    hashScroll : function(){
      var _url = window.location.href;
      if (_url.indexOf("#") > 0){
        var _anchor = _url.substring(_url.indexOf("#") + 1);
        setTimeout(function () {
          $( ".summary a[href='#"+_anchor+"']" ).trigger( "click" );
        }, 500);
      }
    },
    header : function(){
      var doc = document.documentElement,
      w = window,
      bodyDom = $('body'),
      prevScroll = w.scrollY || doc.scrollTop,
      curScroll,
      direction = 0,
      prevDirection = 0,
      headerDom = $('section.header'),
      headerDomHeight = headerDom.outerHeight(),
      toggleHeader = function(direction, curScroll) {
        if (direction === 2 && curScroll > 100) {
          headerDom.addClass('minify');
          prevDirection = direction;
          bodyDom.css('padding-top', headerDomHeight + 'px');
        }
        else if (direction === 1 && curScroll < 100) {
          headerDom.removeClass('minify');
          prevDirection = direction;
          bodyDom.css('padding-top', headerDomHeight + 'px');
        }
      };

      // refresh menu on resize
      var Vwindow = $(window),
      cachedWidth = Vwindow.width();

      var checkScroll = function() {

        /*
        ** Find the direction of scroll
        ** 0 - initial, 1 - up, 2 - down
        */

        curScroll = w.scrollY || doc.scrollTop;
        if (curScroll > prevScroll) {
          //scrolled up
          direction = 2;
        }
        else if (curScroll < prevScroll) {
          //scrolled down
          direction = 1;
        }

        if (direction !== prevDirection) {
          toggleHeader(direction, curScroll);
        }

        prevScroll = curScroll;
      };

      function checkSize(){
        Vwindow.resize(function(){
          var newWidth = Vwindow.width();
          if(newWidth !== cachedWidth){
            cachedWidth = newWidth;
            checkScroll();
          }
        });
        if ($(window).width() > 991) {
          checkScroll();
        }else{
          // bodyDom.css('padding-top', '75px');
          bodyDom.css('padding-top', headerDomHeight + 'px');
        }
      }

      window.addEventListener('scroll', checkSize);
      checkSize();
    },
    isInViewport : function(el){
      // https://www.javascripttutorial.net/dom/css/check-if-an-element-is-visible-in-the-viewport/
      var rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)

      );
    },
    hashActiveTab : function(){
      // Handle active tab from url hash
      var url = window.location.href;
      if (url.indexOf("#") > 0){
        var anchor = url.substring(url.indexOf("#") + 1);
        $('.nav[role="tablist"] a[href="#'+anchor+'"]').tab('show');
      }

      $('a[role="tab"]').on("click", function() {
        var newUrl;
        var hash = $(this).attr("href");
        newUrl = url.split("#")[0] + hash;
        history.replaceState(null, null, newUrl);
      });
    },
    fakeShare : function(){
      $('.share-wrapper .icons a:not(.copy-url)').click(function(e){
        e.preventDefault();
        var $link   = $(this);
        var href    = $link.attr('href');
        var network = $link.attr('data-network');

        var networks = {
          facebook : { width : 600, height : 300 },
          twitter  : { width : 600, height : 254 },
          google   : { width : 515, height : 490 },
          linkedin : { width : 600, height : 473 },
          pinterest : { width : 600, height : 600 }
        };

        var x = screen.width/2 - networks[network].width/2;
        var y = (screen.height/2 - networks[network].height/2)-200;

        var popup = function(network){
          var options = 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,';
          window.open(href, '', options+'height='+networks[network].height+',width='+networks[network].width+',left='+x+',top='+y);
        };

        popup(network);
      });
    },
    copyCurrentPage : function(){

      function setTooltip(message) {
        $('.copy-url').tooltip('hide')
          .attr('data-original-title', message)
          .tooltip('show');
      }

      function hideTooltip() {
        setTimeout(function() {
          $('.copy-url').tooltip('hide');
        }, 3000);
      }

      $('.copy-url').tooltip({
        trigger: 'click',
        placement: 'right'
      });

      $('.copy-url').click(function(e){
        e.preventDefault();
        var dummy = document.createElement('input'),
        text = window.location.href;
        document.body.appendChild(dummy);
        dummy.value = text;
        dummy.select();
        document.execCommand('copy');
        document.body.removeChild(dummy);
        setTooltip('URL de la page copiée dans le presse papier');
        hideTooltip();
      });

    },
    menuMask : function(){
      $('header.banner .has-megamenu').each(function(){
        $( this )
          .mouseenter(function() {
            $(".mask").stop().fadeIn(300);
          })
          .mouseleave(function() {
            $(".mask").stop().fadeOut(300);
          });
      });
    },
    checkSitckyContent : function(){
      // Enable sticky column on module-text-photo when necessary
      var target = $('.module-text-photo,.module-adv-text-photo');

      target.each(function(){
        var _this = $(this);

        if(_this.length > 0){
          var text = _this.find('.text-content'),
          textHeight = text.height(),
          sidebar = _this.find('.sidebar-ref-alt'),
          img = sidebar.find('.img-content'),
          imgHeight = img.height();
          if(textHeight < imgHeight){
            sidebar.addClass('no-sticky');
          }
        }
      });
    },
  };

  var Slider = {
    create: function (id, parameters) {
      var _hasParameters = typeof parameters !== "undefined",
        _sliderParameters = {
          speed: 800,
          navigation: {
            nextEl: id + " .swiper-button-next",
            prevEl: id + " .swiper-button-prev",
          },
          pagination: {
            el: id + " .swiper-pagination",
            // dynamicBullets: true,
            clickable: true,
          },
          loop: true,
          watchOverflow: true,
          watchSlidesVisibility: true,
          autoplay: {
            delay: 5000,
          },
          spaceBetween: 30,
        };

      if (_hasParameters) {
        if (typeof parameters.slidesPerView !== "undefined") {
          _sliderParameters.slidesPerView = parameters.slidesPerView;
        }
        if (typeof parameters.spaceBetween !== "undefined") {
          _sliderParameters.spaceBetween = parameters.spaceBetween;
        }
        if (typeof parameters.breakpoints !== "undefined") {
          _sliderParameters.breakpoints = parameters.breakpoints;
        }
      }

      var slider = new Swiper(id + " .swiper-container", _sliderParameters);
    },
    // createMobileOnly : function (id){},
    initCommonSliders: function () {
      var sliderParameters;
      if ($(".slider-lands").length) {
        Slider.create(".slider-lands");
      }
    },
  };

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        window.WebFontConfig = {
          typekit: {
            id: 'xcq5izl'
          }
        };
        (function() {  var wf = document.createElement('script');
          wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
          wf.type = 'text/javascript';  wf.async = 'true';
          var s = document.getElementsByTagName('script')[0];
          s.parentNode.insertBefore(wf, s);
        })();

        /// Prevent closing from click inside dropdown
        $(document).on('click', '.dropdown-menu', function (e) {
          e.stopPropagation();
        });

        // Back to top
        $('.back-to-top').click(function(e){
          e.preventDefault();
          $("html, body").animate({ scrollTop: $("body").offset().top},1600);
        });

        // Cookie mobile
        $('.load-mobile-cookie').click(function(e){
          e.preventDefault();
          $('.mobile-close').click();
          $('#cookie-law-info-again').click();
        });

        // refresh window on resize NOTE : to avoid bad resize detection (ex: chrome mobile with navbar)
        var width = $(window).width(), height = $(window).height();
        $(window).on('resize',function(){
          if ($(window).width() !== width || $(window).height() !== height) {
            location.reload();
          }
        });

        if ($(window).width() < 992) {
          $('.has-megasubmenu a').click(function(e){
            e.preventDefault();
            $(this).next('.megasubmenu').toggle();

            $('.dropdown').on('hide.bs.dropdown', function () {
              $(this).find('.megasubmenu').hide();
            });
          });

          $('.dropdown-menu a').click(function(e){
            if(!$(this).hasClass('allow-link')){
              e.preventDefault();
            }
            if($(this).next('.submenu').length){
              $(this).next('.submenu').toggle();
            }
            $('.dropdown').on('hide.bs.dropdown', function () {
              $(this).find('.submenu').hide();
            });
          });
        }

        Fn.header();
        Fn.menuMask();
        Fn.checkSitckyContent();
        Slider.initCommonSliders();

        // Pagination
        if ($(".left-pagination").length) {
          $('.left-pagination').hover(function(e) {
            e.stopPropagation();
            $(this).closest('.page-nav').addClass('page-nav-left');
          }, function(e) {
            e.stopPropagation();
            $(this).closest('.page-nav').removeClass('page-nav-left');
          });
        }

        if ($(".right-pagination").length) {
          $('.right-pagination').hover(function(e) {
            e.stopPropagation();
            $(this).closest('.page-nav').addClass('page-nav-right');
          }, function(e) {
            e.stopPropagation();
            $(this).closest('.page-nav').removeClass('page-nav-right');
          });
        }
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'page_template_template_brands': {
      finalize: function() {
        Fn.hashActiveTab();
        // var box = document.querySelector('#filter-tabs');
        //
        // document.addEventListener('scroll', function () {
        //   var bFilters = $('.bottom-filters');
        //   var check = Fn.isInViewport(box) ? bFilters.removeClass('active') : bFilters.addClass('active');
        //
        // }, {
        //   passive: true
        // });
      }
    },
    'page_template_template_terroirs': {
      init: function() {
        Fn.hashScroll();
        // Anchor links from summary.
        $('.summary a').click(function(e){
          e.preventDefault();
          var _anchor = $(this).attr('href'),
              _url = window.location.href,
              _newUrl = _url.split("#")[0] + _anchor;
          history.replaceState(null, null, _newUrl);
          Fn.goTo(_anchor, 1000);
          // $('html, body').animate({scrollTop: $(_anchor).offset().top}, 1000);
        });
        // Init brands slider (for each lang)
        var swiperInstances = {};
        $(".slider-brands .swiper-container").each(function(index, element){
          var $this = $(this);
          $this.addClass("instance-" + index);
          $this.find(".swiper-pagination").addClass("swiper-pagination-" + index);
          swiperInstances[index] = new Swiper(".instance-" + index, {
              speed : 800,
              watchOverflow: true,
              watchSlidesVisibility: true,
              slidesPerView: 1.5,
              spaceBetween: 30,
              pagination: {
                el: ".swiper-pagination-" + index,
                type: 'bullets',
                clickable: true
              },
              loop: true,
              autoplay: {
                delay: 5000,
              },
              breakpoints: {
                992: {
                  slidesPerView: 1.5,
                },
                320: {
                  slidesPerView: 1.1,
                  spaceBetween: 10,
                },
              },
          });
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'page_template_template_savoirfaire': {
      init: function() {
        Fn.hashScroll();
        // Anchor links from summary.
        $('.summary a').click(function(e){
          e.preventDefault();
          var _anchor = $(this).attr('href'),
              _url = window.location.href,
              _newUrl = _url.split("#")[0] + _anchor;
          history.replaceState(null, null, _newUrl);
          Fn.goTo(_anchor, 1000);
          // $('html, body').animate({scrollTop: $(_anchor).offset().top}, 1000);
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'page_template_template_jobs': {
      init: function() {
        // Fake load more jobs
        $('.jobs-seemore').click(function(e){
          e.preventDefault();
          $('.jobs').find('.job-card').each(function() {
            $(this).removeClass('d-none');
          });
          $(this).remove();
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'single_job': {
      init: function() {
        Fn.copyCurrentPage();
        Fn.fakeShare();
      }
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
